import { render, staticRenderFns } from "./MenuBar.vue?vue&type=template&id=6d210577&scoped=true&"
import script from "./MenuBar.vue?vue&type=script&lang=js&"
export * from "./MenuBar.vue?vue&type=script&lang=js&"
import style0 from "./MenuBar.vue?vue&type=style&index=0&id=6d210577&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d210577",
  null
  
)

export default component.exports