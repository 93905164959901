<script>
import _ from 'lodash'
import { mapState } from 'vuex'

import Card from '@/components/card/Card.vue'
import { datetimeMixins, toastMixins } from '@/mixins'
import userService from '@/services/user/user'

export default {
  mixins: [datetimeMixins, toastMixins],
  components: {
    AppCard: Card
  },
  data() {
    return {
      isLoading: false,
      fields: [{ label: 'ชื่อผู้ใช้งาน', key: 'userName', type: 'string' }],
      initData: {
        userName: null
      },
      updateData: {
        userName: null
      },
      errMsg: null
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      defaultDelayMs: state => state.constants.defaultDelayMs
    }),
    saveButtonEnable() {
      const body = {}
      Object.keys(this.updateData).map(key => {
        const oldValue = this.initData[key]
        const newValue = this.updateData[key]
        if (!_.isEqual(oldValue, newValue)) {
          body[key] = newValue
        }
      })
      return !_.isEmpty(body)
    }
  },
  created() {
    this.setUpData()
  },
  methods: {
    setUpData() {
      const keys = this.fields.map(field => field.key)
      this.initData = _.pick(this.user, [keys])
      this.updateData = _.pick(this.user, [keys])
    },
    mapKeyLabel(key) {
      const foundField = this.fields.find(field => field.key === key)
      if (foundField) return foundField.label
      return '-'
    },
    async buttonClicked(type) {
      switch (type) {
        case 'SAVE':
          await this.updateUserBasicInformation()
          break
        case 'CANCEL':
          this.$router.back()
          break
      }
    },
    async updateUserBasicInformation() {
      this.isLoading = true
      try {
        const body = {}
        Object.keys(this.updateData).map(key => {
          const oldValue = this.initData[key]
          const newValue = this.updateData[key]
          if (!_.isEqual(oldValue, newValue)) {
            body[key] = newValue
          }
        })
        await userService.updateOwnBasicInformation(body)
        this.$store.dispatch('fetchOwnInformation')
        this.mxDisplaySuccessMessage('แก้ไขข้อมูลพื้นฐานผู้ใช้งานสำเร็จ')
        setTimeout(() => {
          this.$router.replace({ name: 'User.BasicInfo' })
        }, this.defaultDelayMs)
      } catch (err) {
        console.log(err)
        this.errMsg = err.response.data.thMessage
      }
      this.isLoading = false
    }
  }
}
</script>

<template>
  <div class="py-3">
    <app-card>
      <b-row>
        <!-- topic -->
        <b-col cols="12" class="mb-2">
          <span class="text-lg font-weight-bold">
            ข้อมูลพื้นฐาน
          </span>
        </b-col>

        <!-- content -->
        <b-col
          v-for="(value, key) in updateData"
          :key="key"
          cols="12"
          md="6"
          class="mt-3 d-flex align-items-center"
        >
          <span class="mr-2 font-weight-bold text-nowrap">
            {{ mapKeyLabel(key) }} :
          </span>
          <b-input
            class="w-100"
            v-model="updateData[key]"
            :disabled="isLoading"
          ></b-input>
        </b-col>
      </b-row>

      <!-- hover line -->
      <hr />

      <!-- buttons -->
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-center justify-content-xl-end"
        >
          <b-button
            variant="primary"
            class="mr-2 px-5"
            @click="buttonClicked('SAVE')"
            :disabled="!saveButtonEnable || isLoading"
          >
            <b-spinner v-if="isLoading" variant="light" small></b-spinner>
            <span v-else>บันทึก</span>
          </b-button>
          <b-button
            variant="outline-primary"
            class="px-5"
            @click="buttonClicked('CANCEL')"
            :disabled="isLoading"
          >
            ยกเลิก
          </b-button>
        </b-col>
      </b-row>

      <error-modal
        :displayProp="!!errMsg"
        :errorMessageProp="errMsg"
        @modalClosed="errMsg = null"
      ></error-modal>
    </app-card>
  </div>
</template>

<style scoped></style>
