<script>
import MenuBar from './components/MenuBar.vue'
import EditBasicDetailCard from './components/EditBasicDetailCard.vue'

export default {
  components: {
    MenuBar,
    EditBasicDetailCard
  }
}
</script>

<template>
  <b-container fluid="lg">
    <!-- แถบ menu ด้านบน -->
    <b-row class="d-flex justify-content-between">
      <b-col class="col-12 col-xl-auto py-3 text-center text-xl-left">
        <page-title title="แก้ไขข้อมูลพื้นฐานผู้ใช้งาน"></page-title>
      </b-col>
      <b-col
        class="col-12 col-xl-auto py-3 d-flex justify-content-center justify-content-xl-end"
      >
        <menu-bar></menu-bar>
      </b-col>
    </b-row>

    <!-- แสดงข้อมูลพื้นฐานของ -->
    <edit-basic-detail-card></edit-basic-detail-card>
  </b-container>
</template>

<style></style>
